<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10065')}} &middot; {{t('10066')}} 
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="event_bg">
			<img :src="mev0104[0].EVENT_PIC2" alt="">
		</div>
		<div id="sub" class="sub event" >
			<!-- content -->
			<section class="content" v-if="mev0104.length > 0">
				<div class="box">
          			<div class="event__wrap">
              			<p class="event_wrap_title">{{ mev0104[0].PAGE_COMMENT0}}<br><em>{{ mev0104[0].PAGE_COMMENT1}}</em></p>
						<div class="benefit_wrap">
							{{ mev0104[0].PAGE_COMMENT2}}
							<p>
								<span v-if="mev0104[0].DCCP_TYPE === 'A'">{{ mev0104[0].UNIT_AMT }}</span>
								<span v-else-if="mev0104[0].DCCP_TYPE === 'R'">{{ mev0104[0].DC_RATE }}</span>
							<span v-if="mev0104[0].DCCP_TYPE === 'A'">원</span>
							<span v-else-if="mev0104[0].DCCP_TYPE === 'R'">%</span>
							</p>
							{{ mev0104[0].PAGE_COMMENT3}}
						</div>
						<ul class="event_notice">
							<li v-if="mev0104[0].CP_ISSUE_FLAG === '02'||mev0104[0].CP_ISSUE_FLAG === '03'">{{ notoce_comment }}</li>
							<li>발급 및 사용기간 : {{ dayjs(mev0104[0].EV_SDATE).format("YYYY.MM.DD") }}~{{dayjs(mev0104[0].EV_EDATE).format("YYYY.MM.DD") }}</li>
						</ul>
						<div class="event_btn_wrap">
							<button type="button" @click="goLink('CP')">{{ btnCPtype }}</button>
							<button type="button" @click="goLink('EV')">{{ btnEVtype }}</button>
						</div>
          			</div>
					<img :src="mev0104[0].EVENT_PIC3" alt="" style="margin-top:50px;">
				</div>
		</section>
			<!-- //content -->
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useStore } from "vuex"
import { computed, ref, onMounted } from "vue"
import { useRouter, useRoute } from 'vue-router';
import dayjs from 'dayjs'
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

dayjs.locale("ko");
export default {
	setup() {
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const { t }= useI18n() //번역필수 모듈
		const notoce_comment = ref();
    	const mev0104 = computed(() => store.state.mev01.mev0104);
		const evNo = route.params.id;
		const btnCPtype = ref();
		const btnEVtype = ref();
		const btnCPlink = ref();
		const btnEVlink = ref();
		const cptype = ref();
		const evtype = ref();
		const goBack = () => {
        router.go(-1);
		};
		
		onMounted(() => {
			store.dispatch("mev01/fetchMev0104",{
				proc_cd: "01",
				evNo: evNo
			});
			cptype.value = store.state.mev01.mev0104[0].CP_ISSUE_FLAG;
			evtype.value = store.state.mev01.mev0104[0].EV_TYPE;
			if(cptype.value === '02' || cptype.value === '03'){
				btnCPtype.value = '신규회원가입하기'
				btnCPlink.value = '/join/step1'
			}
			if(evtype.value === '01'){
				btnEVtype.value = '포인트확인하기'
				btnEVlink.value = '/mypage/point'
				notoce_comment.value = '회원가입하면 포인트가 자동 지급됩니다.'
			}else if(evtype.value === '02'){
				btnEVtype.value = '쿠폰함확인하기'
				btnEVlink.value = '/mypage/coupon'
				notoce_comment.value = '회원가입하면 할인쿠폰이 자동 지급됩니다.'
			}
    	});
		return {
			dayjs,
			goBack,
			mev0104,
			t,
			i18n,
			btnCPtype,
			btnEVtype,
			notoce_comment,
			btnCPlink,
			btnEVlink,
			goLink: async (type) => {
				if(type === 'CP'){
					router.push(btnCPlink.value);
				}else if(type === 'EV'){
					router.push(btnEVlink.value);
				}
			},
		}
	},
	components: {
	},
}
</script>